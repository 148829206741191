import { HomeProductGroupBannerItem } from "@interfaces/dto/home-product-group.dto";
import Link from "next/link";
type Props = {
  banners: HomeProductGroupBannerItem[];
};

const HomeProductGroupBanner = ({ banners }: Props) => {
  const banner = banners && banners.length > 0 ? banners[0] : undefined;
  return (
    <>
      {/* {banners.map((item, idx) => (
        <a key={idx} href="#">
          <img src={item.image} alt="" />
        </a>
      ))} */}
      {banner && (
        <Link href={banner.link}>
          <a>
            <img src={banner.image} alt={banner.title} className="img-fluid" />
          </a>
          {/* <img
              alt=""
              src="/assets/images/dummy-product-group.jpg"
              className="img-fluid"
            /> */}
        </Link>
      )}
    </>
  );
};

export default HomeProductGroupBanner;
