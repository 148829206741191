import { BestSellerMenu } from "./BestSellerMenu";
import { useHomeBestSellerMenu } from "@api/home-best-seller/bestSellerMenu";
import { useHomeBestSellerProducts } from "@api/home-best-seller/bestSellerProducts";
import { useEffect, useState } from "react";
import Loader from "@components/atoms/Loader";
import BestSellerProducts from "./BestSellerProducts";
import {
  PRODUCT_DEFAULT_PAGE,
  PRODUCT_DEFAULT_SORT_BY,
} from "@const/products.const";
import { ProductSortBy } from "@interfaces/components/product-filters.interface";
import SliderSwiper from "@components/atoms/SliderSwiper";
import SkeletonProductItem from "@components/atoms/skeleton/ui-product-item";

export const HomeBestSeller = () => {
  const [activeCategoryId, setActiveCategoryId] = useState<number>(0);
  const [selectedPage, setSelectedPage] =
    useState<number>(PRODUCT_DEFAULT_PAGE);
  const [selectedSortBy, setSelectedSortBy] = useState<ProductSortBy>(
    PRODUCT_DEFAULT_SORT_BY
  );

  const {
    data: dataHomeBestSellerMenu,
    isLoading: isLoadingHomeBestSellerMenu,
  } = useHomeBestSellerMenu();

  useEffect(() => {
    if (!isLoadingHomeBestSellerMenu) {
      const activeMenuCateId = dataHomeBestSellerMenu?.items[0].id;
      setActiveCategoryId(
        activeMenuCateId !== undefined ? activeMenuCateId : 0
      );
    }
  }, [isLoadingHomeBestSellerMenu, dataHomeBestSellerMenu?.items]);

  const {
    data: dataHomeBestSellerProducts,
    isLoading: isLoadingHomeBestSellerProducts,
  } = useHomeBestSellerProducts({
    categoryId:
      activeCategoryId > 0
        ? activeCategoryId
        : (dataHomeBestSellerMenu?.items[0].id as number),
    productDisplayConfig: {
      pageSize: 5,
      currentPage: selectedPage,
      sortBy: selectedSortBy,
      soryDirection: "ASC",
    },
  });

  // handle active bestseller category change
  const handleBestSelerCategory = (categoryId: number) => {
    setActiveCategoryId(categoryId);
  };

  return (
    <>
      <div className="title d-lg-flex align-items-center justify-content-between">
        <h4 className="best-seller">สินค้าขายดี</h4>
        {!isLoadingHomeBestSellerMenu ? (
          <BestSellerMenu
            items={dataHomeBestSellerMenu!.items}
            onCategoryChange={handleBestSelerCategory}
            activeId={activeCategoryId}
          />
        ) : (
          ""
        )}
      </div>
      <div className="product-list-grid product-col-lg-5 product-best-seller">
        {/* <div className="row">
          {isLoadingHomeBestSellerProducts ? (
            <Loader />
          ) : (
            dataHomeBestSellerProducts?.items && (
              <SliderSwiper
                isBestSeller={true}
                dataItems={dataHomeBestSellerProducts?.items}
              />
            )
          )}
        </div> */}
        {isLoadingHomeBestSellerProducts ? (
          <SkeletonProductItem />
        ) : (
          dataHomeBestSellerProducts?.items && (
            <div className="row">
              <SliderSwiper dataItems={dataHomeBestSellerProducts?.items} />
            </div>
          )
        )}
      </div>
    </>
  );
};
