import { FlashsaleProductsResponse } from "@interfaces/dto/products.dto";
import { getFilterQuery, getPageQuery } from "../products/_utils";
import { composeQueryParams } from "@utils/query.utils";
import useSWR from "swr";
import { fetcher } from "@api/_base";
import { ProductDisplayConfig } from "@interfaces/components/product-filters.interface";

export const useHomeFlashSaleProducts = ({
  categoryId,
  productDisplayConfig,
}: {
  categoryId: string | undefined;
  productDisplayConfig: ProductDisplayConfig;
}): FlashsaleProductsResponse => {
  let query: string | undefined = undefined;
  // require categoryId
  if (categoryId) {
    const queryCat = getFilterQuery({
      field: "category_id",
      value: categoryId,
      layer: 0,
    });
    // [page]
    const { pageSize, currentPage } = productDisplayConfig;
    const queryPage = getPageQuery({
      pageSize: pageSize,
      currentPage: currentPage,
    });

    query = composeQueryParams([queryCat, queryPage]);
  }

  const { data, error, mutate } = useSWR(
    query ? `/nextapi/products?${query}` : undefined,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
