import {
  useBannerImages,
  useHomeCategories,
} from "@api/home-category/home-category";
import Loader from "@components/atoms/Loader";
import SkeletonHomeCategory from "@components/atoms/skeleton/ui-home-category";
import { removeHtmlLegacyFromUrl } from "@utils/query.utils";
import Link from "next/link";
import { useState } from "react";
import Slider from "react-slick";

const HomeCategory = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);

  const {
    data: dataCats,
    isLoading: isLoadingCats,
    error: errorCats,
  } = useHomeCategories();

  // const SortMenuHorizontal = dataCats.menu_horizontal?.sort((a, b) => {
  //   if (a.position < b.position) {
  //     return -1;
  //   }
  // });

  const {
    data: dataBanners,
    isLoading: isLoadingBanners,
    error: errorBanners,
  } = useBannerImages();

  const isLoading = isLoadingCats || isLoadingBanners;

  const settings = {
    dots: true,
    dotsClass: "carousel-indicators d-flex",
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (_prev: number, next: number) => setCurrentPage(next),
    customPaging: (i: number) => {
      return (
        <div
          style={{
            background: currentPage === i ? "red" : "white",
            width: "10px",
            height: "10px",
            margin: 5,
            borderRadius: "25px",
            cursor: "pointer",
          }}
        />
      );
    },
  };

  return (
    <>
      {isLoading ? (
        <SkeletonHomeCategory />
      ) : (
        dataCats &&
        dataCats.menu_vertical &&
        !errorCats &&
        dataBanners &&
        dataBanners.banner_slider.length > 0 &&
        dataBanners.banner_bottom.length > 0 &&
        dataBanners.banner_right.length > 0 &&
        !errorBanners && (
          <section className="banner pb-0 pb-sm-3">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-lg-3 pe-md-0">
                  <div className="bg-white h-100 d-none d-lg-block rounded-2">
                    <ul className="navbar-nav">
                      <li className="nav-item dropdown">
                        <ul className="dropdown-menu show rounded-2">
                          {dataCats.menu_vertical.map((menu, idx) => (
                            <li key={idx} className="dropdown-submenu">
                              <Link
                                href={removeHtmlLegacyFromUrl(menu.urlpath)}
                              >
                                <a
                                  data-bs-toggle="dropdown"
                                  className="dropdown-item"
                                >
                                  <img
                                    src={menu.icon_menu}
                                    alt={menu.label}
                                    className="icon-category"
                                  />
                                  {menu.label}
                                </a>
                              </Link>
                              {menu.sub_category_l2.length > 0 ? (
                                <ul className="dropdown-menu rounded-2">
                                  {menu.sub_category_l2.map((l2m, idx2) => (
                                    <li
                                      key={idx2}
                                      className="dropdown-item d-flex"
                                    >
                                      <div>
                                        <Link
                                          href={removeHtmlLegacyFromUrl(
                                            l2m.urlpath
                                          )}
                                        >
                                          <a>{l2m.label}</a>
                                        </Link>
                                      </div>
                                      {l2m.sub_category_l3.length > 0 ? (
                                        <div className="flex-grow-1">
                                          {l2m.sub_category_l3.map(
                                            (l3m, idx3) => (
                                              <Link
                                                key={idx3}
                                                href={removeHtmlLegacyFromUrl(
                                                  l3m.urlpath
                                                )}
                                              >
                                                <a>{l3m.label}</a>
                                              </Link>
                                            )
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              ) : (
                                ""
                              )}
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 col-lg-9">
                  <div className="row">
                    <div className="col-12 col-lg-9">
                      <Slider {...settings}>
                        {dataBanners.banner_slider.map((item, idx) => {
                          return (
                            <>
                              <Link href={item.link}>
                                <img
                                  key={idx}
                                  src={item.image}
                                  alt={item.title}
                                  className="rounded-2"
                                />
                              </Link>
                            </>
                          );
                        })}
                      </Slider>
                    </div>
                    <div className="col-12 col-lg-3 ps-lg-0 mt-3 mt-lg-0">
                      <div className="mama-mobile d-flex d-none d-lg-block align-items-center justify-content-center h-100 ">
                        <Link href={dataBanners.banner_right[0].link}>
                          <a>
                            <img
                              src={dataBanners.banner_right[0].image}
                              alt={dataBanners.banner_right[0].title}
                              className="icon-mama d-block rounded-2"
                              style={{ width: "97%" }}
                            />
                          </a>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-none d-g-block">
                <div className="col-12">
                  <Link href={dataBanners.banner_bottom[0].link}>
                    <a>
                      <img
                        className="box-services bg-white mt-3 mt-md-2 rounded-2"
                        src={dataBanners.banner_bottom[0].image}
                        alt={dataBanners.banner_bottom[0].title}
                      />
                    </a>
                  </Link>
                </div>
              </div>

              {/* Mobile Horizontal Menu */}
              <div className="horizontal-menu d-block d-lg-none">
                <div className="row row-cols-4 g-1">
                  {dataCats.menu_horizontal
                    ?.sort((a, b) => (a.position < b.position ? -1 : 1))
                    .map((data, id) => {
                      return (
                        <div key={id} className="col text-center">
                          <Link href={`/${data.urlpath}`}>
                            <a>
                              <img src={data.pathimage} alt={data.label} />
                            </a>
                          </Link>
                          <Link href={`/${data.urlpath}`}>
                            <a>
                              <p>{data.label}</p>
                            </a>
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </section>
        )
      )}
    </>
  );
};

export default HomeCategory;
