import { FLASHSALE_CATEGORY_ID } from "@const/flashsale-category.const";
import { useHomeFlashSaleProducts } from "@api/home-flashsale/home-flashsale";
import Loader from "@components/atoms/Loader";
import { useState } from "react";
import { PRODUCT_DEFAULT_PAGE, PRODUCT_PAGE_SIZE } from "@const/products.const";
import Link from "next/link";
import Clock from "@components/atoms/Clock";
import SliderSwiper from "@components/atoms/SliderSwiper";
import SkeletonProductItem from "@components/atoms/skeleton/ui-product-item";

export const HomeFlashsale = () => {
  // set paging states
  const [selectedPage, setSelectedPage] =
    useState<number>(PRODUCT_DEFAULT_PAGE);

  // get FlashSaleProducts
  const {
    data: dataHomeFlashSaleProducts,
    isLoading: isLoadingHomeFlashSaleProducts,
  } = useHomeFlashSaleProducts({
    categoryId: FLASHSALE_CATEGORY_ID,
    productDisplayConfig: {
      currentPage: selectedPage,
      pageSize: PRODUCT_PAGE_SIZE,
    },
  });

  return (
    <>
      <div className="title d-flex align-items-center mb-0 mb-sm-3 mb-md-0">
        <h4 className="mt-3 flash-sale mx-2">FLASH SALE</h4>
        {/* <i className="icon icon-alarm-clock mx-2 mx-md-3"></i> */}
        <Clock />
        <Link href="/flashsale">
          <a className="view-all ms-auto">
            ดูทั้งหมด <i className="icon icon-arrow-right mx-2"></i>
          </a>
        </Link>
      </div>

      <div className="product-list-grid product-col-lg-5 pb-md-0">
        {isLoadingHomeFlashSaleProducts ? (
          <SkeletonProductItem />
        ) : (
          dataHomeFlashSaleProducts?.items && (
            <div className="row">
              <div className="col">
                <SliderSwiper dataItems={dataHomeFlashSaleProducts?.items} />
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};
