import { RecommendProductsResponse } from "@interfaces/dto/products.dto";
import { getFilterQuery, getPageQuery, getSortingQuery } from "../products/_utils";
import { composeQueryParams } from "@utils/query.utils";
import useSWR from "swr";
import { fetcher } from "@api/_base";
import { ProductDisplayConfig } from "@interfaces/components/product-filters.interface";

export const useHomeRecommendProducts = ({
  categoryId,
  productDisplayConfig,
}: {
  categoryId: string | undefined;
  productDisplayConfig: ProductDisplayConfig;
}): RecommendProductsResponse => {
  let query: string | undefined = undefined;
  // require categoryId
  if (categoryId) {
    const queryCat = getFilterQuery({
      field: "category_id",
      value: categoryId,
      layer: 0,
    });
    // [page]
    const { pageSize, currentPage, sortBy } = productDisplayConfig;
    const queryPage = getPageQuery({
      sortBy: sortBy,
      pageSize: pageSize,
      currentPage: currentPage,
    });

    // Custom Sorting
    //const queryCustomSorting = getSortingQuery({field : "custom_sorting", layer: 0})
    //query = composeQueryParams([queryCat, queryPage, queryCustomSorting]);
    query = composeQueryParams([queryCat, queryPage]);
  }

  const { data, error, mutate } = useSWR(
    query ? `/nextapi/product-recommendations?${query}` : undefined,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
