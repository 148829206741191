import Slider from "react-slick";
import Link from "next/link";
import { removeHtmlLegacyFromUrl } from "@utils/query.utils";

import { HomePromotionItem } from "@interfaces/dto/home-promotion.dto";

interface Props {
  productImages: HomePromotionItem[];
}

export const PromotionCarousel = ({ productImages }: Props) => {
  // Previous Button
  function PrevArrow(props: any) {
    const { onClick } = props;
    return (
      <a
        className="carousel-control-prev bg-transparent w-aut"
        role="button"
        data-bs-slide="prev"
        onClick={onClick}
      >
        <span
          className="carousel-control-prev-icon rounded-1"
          aria-hidden="true"
        ></span>
      </a>
    );
  }

  // Next Button
  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <a
        className="carousel-control-next bg-transparent w-aut"
        role="button"
        data-bs-slide="next"
        onClick={onClick}
      >
        <span
          className="carousel-control-next-icon rounded-1"
          aria-hidden="true"
        ></span>
      </a>
    );
  }

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div
      id="promorionsCarousel"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      {productImages.length > 0 ? (
        <div className="carousel-item active">
          <Slider {...settings}>
            {productImages.map(({}, idx) => {
              const url = productImages[idx].image;
              const alt = productImages[idx].image;
              return (
                <div className="col-6" key={idx}>
                  <Link href={removeHtmlLegacyFromUrl(productImages[idx].link)}>
                    <a className="card">
                      <img
                        className="card-image rounded-2"
                        src={url}
                        alt={alt}
                      />
                    </a>
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

{
  /* <div className="carousel-inner" role="listbox">
  <Carousel
    activeIndex={index}
    onSelect={handleSelect}
    interval={null}
    controls={false}
    indicators={false}
  >
    {productImages.map(({}, idx) => {
      if (
        loopIdx < productImages.length &&
        productImages[loopIdx + 1] !== undefined &&
        productImages[loopIdx] !== undefined
      ) {
        const sUrl = productImages[loopIdx + 1].image;
        const sAlt = productImages[loopIdx + 1].image;
        const url = productImages[loopIdx].image;
        const alt = productImages[loopIdx].image;
        loopIdx = loopIdx + 2;
        return (
          <Carousel.Item key={idx}>
            <div className="col-6">
              <a href="#" className="card">
                <img className="card-image" src={url} alt={alt} />
              </a>
            </div>
            <div className="col-6">
              <a href="#" className="card">
                <img className="card-image" src={sUrl} alt={sAlt} />
              </a>
            </div>
          </Carousel.Item>
        );
      } else if (
        loopIdx < productImages.length &&
        productImages[loopIdx + 1] === undefined &&
        productImages[loopIdx] !== undefined
      ) {
        const url = productImages[loopIdx].image;
        const alt = productImages[loopIdx].image;
        const url2 = productImages[0].image;
        const alt2 = productImages[0].image;
        loopIdx = loopIdx + 2;
        return (
          <Carousel.Item key={idx}>
            <div className="col-6">
              <a href="#" className="card">
                <img className="card-image" src={url} alt={alt} />
              </a>
            </div>
            <div className="col-6">
              <a href="#" className="card">
                <img className="card-image" src={url2} alt={alt2} />
              </a>
            </div>
          </Carousel.Item>
        );
      }
    })}
  </Carousel>
  <a
    className="carousel-control-prev bg-transparent w-aut"
    role="button"
    data-bs-slide="prev"
    onClick={() => handleIdxChange(-1)}
  >
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
  </a>
  <a
    className="carousel-control-next bg-transparent w-aut"
    role="button"
    data-bs-slide="next"
    onClick={() => handleIdxChange(1)}
  >
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
  </a>
</div>; */
}
