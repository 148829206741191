import { HomeBestSellerMenu } from "@interfaces/dto/home-best-seller.dto";

interface Props {
  items: HomeBestSellerMenu[];
  onCategoryChange: (categoryId: number) => void;
  activeId: number;
}

export const BestSellerMenu = ({
  items,
  onCategoryChange,
  activeId,
}: Props) => {
  return (
    <div className="category-responsive">
      <div className="category-menu ms-md-auto">
        {items.map((item) => (
          <a
            key={item.id}
            onClick={() => onCategoryChange(item.id)}
            className={activeId === item.id ? "active" : ""}
          >
            {item.name}
          </a>
        ))}
        {/* <a href="#">ทีวี</a>
        <a href="#" className="active">
          เครื่องปรับอากาศ/เครื่องฟอกอากาศ
        </a>
        <a href="#">ตู้เย็น</a>
        <a href="#">เครื่องซักผ้า/เครื่องอบผ้า</a>
        <a href="#">ครัวขนาดใหญ่</a>
        <a href="#">
          <i className="icon icon-arrow-right mx-2" />
        </a> */}
      </div>
    </div>
  );
};
