import useSWR from "swr";
import { fetcher } from "@api/_base";
import { HomeHotBrandResponse } from "@interfaces/dto/home-hot-brand.dto";

export const useHomeHotBrand = (): HomeHotBrandResponse => {
  const { data, error, mutate } = useSWR("/nextapi/brands", fetcher);

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
