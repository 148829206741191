import React from "react";
import ContentLoader from "react-content-loader";

type Props = {};

const SkeletonProductGroupBanner = (props: Props) => {
  const bgColor = "#fbfbfb";
  const contentHeight = 350;
  return (
    <div>
      <ContentLoader
        speed={2}
        width={"100%"}
        height={contentHeight}
        viewBox={`0 0 100% ${contentHeight}`}
        backgroundColor={bgColor}
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="3" ry="3" width="60%" height="20" />
        <rect x="0" y="30" rx="10" ry="10" width="100%" height="300" />
      </ContentLoader>
    </div>
  );
};

export default SkeletonProductGroupBanner;
