import { HomeHotBrandItem } from "@interfaces/dto/home-hot-brand.dto";
import Slider from "react-slick";
import Link from "next/link";
import { useWindowDimensions } from "@utils/common.util";

type Props = {
  datas: HomeHotBrandItem[];
};

const HomeHotBrandCarousel = ({ datas }: Props) => {
  // Previous Button
  function PrevArrow(props: any) {
    const { onClick } = props;
    return (
      <a
        className="carousel-control-prev bg-transparent w-aut"
        role="button"
        data-bs-slide="prev"
        onClick={onClick}
      >
        <span
          className="carousel-control-prev-icon rounded-1"
          aria-hidden="true"
        ></span>
      </a>
    );
  }

  // Next Button
  function NextArrow(props: any) {
    const { onClick } = props;
    return (
      <a
        className="carousel-control-next bg-transparent w-aut"
        role="button"
        data-bs-slide="next"
        onClick={onClick}
      >
        <span
          className="carousel-control-next-icon rounded-1"
          aria-hidden="true"
        ></span>
      </a>
    );
  }

  let sizeScreen = useWindowDimensions().width;

  const settings = {
    dots: sizeScreen < 768 ? true : false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 8000,
    slidesToShow: 8,
    slidesToScroll: 8,
    arrows: sizeScreen < 768 ? false : true,
    rows: 2,

    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
    ],
  };

  return (
    <>
      <div
        id="hotbrandCarousel"
        className="carousel-hotbrand-list carousel slide"
        data-bs-ride="carousel"
      >
        <div className="carousel-item active row gx-1">
          <Slider {...settings}>
            {datas.map((data, id) => {
              return (
                <div key={id} className="pt-0 pb-0 p-1">
                  <Link href={data.url_path}>
                    <img
                      style={{ cursor: "pointer" }}
                      src={data.image ? data.image : "IMAGE_NOT_FOUND"}
                      alt={data.name}
                      className="img-fluid rounded-2"
                    />
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default HomeHotBrandCarousel;
