import { HomeBestSellerMenuResponse } from "@interfaces/dto/home-best-seller.dto";
import { getFilterQuery } from "../products/_utils";
import { composeQueryParams } from "@utils/query.utils";

import useSWR from "swr";
import { fetcher } from "@api/_base";
import { BEST_SELLER_CATEGORY_ID } from "@const/best-seller-category.const";

export const useHomeBestSellerMenu = (): HomeBestSellerMenuResponse => {
  const queryCat = getFilterQuery({
    field: "parent_id",
    value: BEST_SELLER_CATEGORY_ID,
    layer: 0,
  });
  let query: string | undefined = undefined;

  const queryFields = `fields=items[id,name,custom_attributes[url_path]]`;

  query = composeQueryParams([queryCat, queryFields]);

  const { data, error, mutate } = useSWR(`/categories/list?${query}`, fetcher);

  return {
    data: data ? data : undefined,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
