import { useHomePromotion } from "@api/promotions/home-promotion";
import { PromotionCarousel } from "@components/features/home-promotion/PromotionCarousel";
import Loader from "@components/atoms/Loader";
import { HomePromotionItem } from "@interfaces/dto/home-promotion.dto";
import Link from "next/link";
import { removeHtmlLegacyFromUrl } from "@utils/query.utils";

export const HomePromotion = () => {
  const {
    data: dataHomePromotion,
    isLoading: isLoadingHomePromotion,
    error: errorHomePromotion,
  } = useHomePromotion();

  return (
    <>
      {!isLoadingHomePromotion ? (
        <>
          <h4>PROMOTIONS</h4>
          <div className="product-highlight product-promotion">
            <div className="row">
              {dataHomePromotion?.banner_left?.map((item, idx) => (
                <div key={idx} className="col-12 col-md-6">
                  <Link href={removeHtmlLegacyFromUrl(item.link)}>
                    <a className="card">
                      <img
                        alt="left-banner-promotion"
                        src={item.image}
                        className="card-img rounded-2"
                      />
                      <div className="card-img-overlay d-flex flex-column">
                        {/* <div>
                          <span className="badge badge-off">off 50%</span>
                          <h5 className="card-name">
                            Alectric ไมโครเวฟ 20 ลิตร
                          </h5>
                          <p className="card-detail w-50">
                            ไมโครเวฟจากแบรนด์ Alectric ขนาด 20 ลิตร รุ่น MO-M2
                            ประตูเป็นประตูกระจกนิรภัย
                          </p>
                          <p className="card-price">
                            <span>3,990.-</span>
                            <del>7,990.-</del>
                          </p>
                        </div>
                        <span className="shop-now mt-auto mb-3 mb-md-2">
                          Shop Now <i className="icon icon-arrow-down-circle" />
                        </span> */}
                      </div>
                    </a>
                  </Link>
                </div>
              ))}
              <div className="col-12 col-md-6">
                {/* html promotion carousel */}
                <div className="row">
                  <PromotionCarousel
                    productImages={
                      dataHomePromotion?.banner_slider as HomePromotionItem[]
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
