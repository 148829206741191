import { getFilterQuery, getPageQuery } from "../products/_utils";
import { composeQueryParams } from "@utils/query.utils";
import useSWR from "swr";
import { fetcher } from "@api/_base";
import { ProductDisplayConfig } from "@interfaces/components/product-filters.interface";
import { HomeProductGroupResponse } from "@interfaces/dto/products.dto";
import {
  HomeProductGroupBannerResponse,
  HomeProductGroupTabMenuResponse,
} from "@interfaces/dto/home-product-group.dto";

export const useHomeProductGroup = ({
  categoryId,
  productDisplayConfig,
}: {
  categoryId: string | undefined;
  productDisplayConfig: ProductDisplayConfig;
}): HomeProductGroupResponse => {
  let query: string | undefined = undefined;
  // require categoryId
  if (categoryId) {
    const queryCat = getFilterQuery({
      field: "category_id",
      value: categoryId,
      layer: 0,
    });
    // [page]
    const { pageSize, currentPage, sortBy, soryDirection } = productDisplayConfig;
    const queryPage = getPageQuery({
      pageSize: pageSize,
      currentPage: currentPage,
      sortBy: sortBy,
      soryDirection: soryDirection
    });

    query = composeQueryParams([queryCat, queryPage]);
  }

  const { data, error, mutate } = useSWR(
    query ? `/nextapi/products?${query}` : undefined,
    fetcher, {
      revalidateOnFocus: false
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useHomeProductGroupBanner = (
  categoryId: string | undefined
): HomeProductGroupBannerResponse => {
  const { data, error, mutate } = useSWR(
    categoryId ? `/nextapi/banner-sections/${categoryId}` : undefined,
    fetcher
  );

  return {
    data: data && data.length > 0 ? data[0] : undefined,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};

export const useHomeProductGroupTabMenu = (
  categoryId: string | undefined
): HomeProductGroupTabMenuResponse => {
  const { data, error, mutate } = useSWR(
    categoryId ? `/nextapi/home-linkmenu/${categoryId}` : undefined,
    fetcher
  );

  return {
    data: data,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
