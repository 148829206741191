// import required modules
import { FreeMode, Pagination, Navigation } from "swiper/modules";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Product } from "@interfaces/dto/products.dto";
import GridItem from "@components/features/product-list/items/GridItem";
import { HomeProductGroupBannerItem } from "@interfaces/dto/home-product-group.dto";
import Link from "next/link";
import { useWindowDimensions } from "@utils/common.util";

type Props = {
  dataItems: Product[];
  isBestSeller?: boolean;
  bannerImg?: HomeProductGroupBannerItem[] | undefined;
  sliderPerView?: number;
};

const SliderSwiper = ({
  dataItems,
  isBestSeller,
  bannerImg,
  sliderPerView,
}: Props) => {
  const banner = bannerImg && bannerImg.length > 0 ? bannerImg[0] : undefined;
  let sizeScreen = useWindowDimensions().width;

  return (
    <Swiper
      modules={[FreeMode, Navigation]}
      slidesPerView={2}
      spaceBetween={10}
      freeMode={true}
      // modules={[FreeMode, Pagination]}
      pagination={{
        type: "progressbar",
        // clickable: true,
      }}
      breakpoints={{
        480: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: sliderPerView ? sliderPerView : 4,
        },
        1024: {
          slidesPerView: sliderPerView ? sliderPerView : 5,
        },
      }}
      navigation={true}
      className="mySwiper"
    >
      {banner && sizeScreen <= 768 ? (
        <SwiperSlide>
          <Link href={banner.link}>
            <a>
              <img
                src={banner.image}
                alt={banner.title}
                className="img-fluid rounded-4"
              />
              {/* <img
              alt=""
              src="/assets/images/dummy-product-group.jpg"
              className="img-fluid rounded-4"
              /> */}
            </a>
          </Link>
        </SwiperSlide>
      ) : (
        ""
      )}

      {dataItems.map((data, id) => {
        return (
          <SwiperSlide key={id}>
            <GridItem
              product={data}
              is_bestseller={isBestSeller}
              item_number={id + 1}
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default SliderSwiper;
