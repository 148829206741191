export const HOME_PRODUCT_GROUP_1 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_1 as string;
export const HOME_PRODUCT_GROUP_2 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_2 as string;
export const HOME_PRODUCT_GROUP_3 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_3 as string;
export const HOME_PRODUCT_GROUP_4 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_4 as string;
export const HOME_PRODUCT_GROUP_5 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_5 as string;
export const HOME_PRODUCT_GROUP_6 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_6 as string;

export const HOME_PRODUCT_GROUP_1_SECTION_1 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_1_SECTION_1 as string;
export const HOME_PRODUCT_GROUP_2_SECTION_2 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_2_SECTION_2 as string;
export const HOME_PRODUCT_GROUP_3_SECTION_3 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_3_SECTION_3 as string;
export const HOME_PRODUCT_GROUP_4_SECTION_4 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_4_SECTION_4 as string;
export const HOME_PRODUCT_GROUP_5_SECTION_5 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_5_SECTION_5 as string;
export const HOME_PRODUCT_GROUP_6_SECTION_6 = process.env
  .NEXT_PUBLIC_HOME_PRODUCT_GROUP_6_SECTION_6 as string;
