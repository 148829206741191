import {
  HomePromotionResponse,
} from "@interfaces/dto/home-promotion.dto";

import useSWR from "swr";
import { fetcher } from "@api/_base";

export const useHomePromotion = (): HomePromotionResponse => {
  const { data, error, mutate } = useSWR("/nextapi/banner-sections/3", fetcher);

  return {
    data: data && data.length > 0 ? data[0] : undefined,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
