import {
    HomePromotionDailyResponse,
  } from "@interfaces/dto/home-promotion-daily.dto";
  
  import useSWR from "swr";
  import { fetcher } from "@api/_base";
  
  export const useHomePromotionDialy = (): HomePromotionDailyResponse => {
    const { data, error, mutate } = useSWR("/nextapi/promotion/daily", fetcher);
  
    return {
      data: data && data.length > 0 ? data[0] : undefined,
      isLoading: !error && !data,
      error: error,
      mutate: mutate,
    };
  };
  