import { useHomeHotBrand } from "@api/home-hot-brand/home-hot-brand";
import HomeHotBrandCarousel from "./HomeHotBrandCarousel";
import Loader from "@components/atoms/Loader";
import { SkeletonBrandItem } from "@components/atoms/skeleton/ui-brand-item";
import Link from "next/link";

const HomeHotBrand = () => {
  // get HotBrands Products
  const { data: dataHomeHotBrand, isLoading: isLoadingHomeHotBrand } =
    useHomeHotBrand();
  return (
    <>
      <div>
        <div className="title d-flex align-items-center mb-0 mb-sm-3 mb-md-0">
          <h4 className="my-2">HOT BRAND</h4>
          <Link href="/brands">
            <a className="view-all ms-auto">
              ดูทั้งหมด <i className="icon icon-arrow-right mx-2" />
            </a>
          </Link>
        </div>

        <div className="hot-brand">
          <div className="table-responsive-md">
            {isLoadingHomeHotBrand ? (
              <div className="row custom-grid">
                <SkeletonBrandItem count={8} />
              </div>
            ) : (
              dataHomeHotBrand && (
                <div className="row">
                  <HomeHotBrandCarousel datas={dataHomeHotBrand} />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeHotBrand;
