import { Product } from "@interfaces/dto/products.dto";
import GridItem from "@components/features/product-list/items/GridItem";
import React from "react";

type Props = {
  dataRecommendProduct: Product[];
};

export const HomeRecommendItem = ({ dataRecommendProduct }: Props) => {
  return (
    <>
      <div className="row row-cols-2 row-cols-sm-4 row-cols-lg-5">
        {dataRecommendProduct.map((data, id) => {
          return (
            <div key={id} className="col">
              <GridItem product={data} />
            </div>
          );
        })}
      </div>
    </>
  );
};
