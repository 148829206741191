import { HomeHotBrandItem } from "@interfaces/dto/home-hot-brand.dto";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

type Props = {
  count: number;
};

// Item In Home Page
export const SkeletonBrandItem = ({ count = 1 }: Props) => {
  const bgColor = "#fbfbfb";
  const itemSkeleton = Array.from({ length: count }).map((_, index) => (
    <div key={index} className="col-lg-3 col-3">
      <ContentLoader
        speed={2}
        width={"100%"}
        height={125}
        viewBox="0 0 100% 260"
        backgroundColor={bgColor}
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100" />
        <rect x="0" y="110" rx="3" ry="3" width="100%" height="15" />
      </ContentLoader>
    </div>
  ));
  return <>{itemSkeleton}</>;
};

// Item In Brand Page
export const SkeletonBrandPage = ({ count = 1 }: Props) => {
  const bgColor = "#fbfbfb";
  const itemSkeleton = Array.from({ length: count }).map((_, index) => (
    <div key={index} className="col-4 col-sm-3 col-lg-2 mb-3">
      <ContentLoader
        speed={2}
        width={"100%"}
        height={125}
        viewBox="0 0 100% 260"
        backgroundColor={bgColor}
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="10" ry="10" width="100%" height="100" />
        <rect x="0" y="110" rx="3" ry="3" width="100%" height="15" />
      </ContentLoader>
    </div>
  ));
  return <>{itemSkeleton}</>;
};
