import { NEW_ARRIVAL_CATEGORY_ID } from "@const/new-arrival-category.const";
import { useHomeNewArrivalProducts } from "@api/home-new-arrival/home-new-arrival";
import Loader from "@components/atoms/Loader";
import { useState } from "react";
import {
  PRODUCT_DEFAULT_PAGE,
  PRODUCT_DEFAULT_SORT_BY,
  PRODUCT_PAGE_SIZE,
} from "@const/products.const";
import { ProductSortBy } from "@interfaces/components/product-filters.interface";
import SliderSwiper from "@components/atoms/SliderSwiper";
import SkeletonProductItem from "@components/atoms/skeleton/ui-product-item";
import Link from "next/link";

export const HomeNewArrival = () => {
  // set paging & sortby states
  const [selectedPage, setSelectedPage] =
    useState<number>(PRODUCT_DEFAULT_PAGE);
  const [selectedSortBy, setSelectedSortBy] = useState<ProductSortBy>(
    PRODUCT_DEFAULT_SORT_BY
  );

  // get NewArrivalProducts
  const {
    data: dataHomeNewArrivalProducts,
    isLoading: isLoadingHomeNewArrivalProducts,
  } = useHomeNewArrivalProducts({
    categoryId: NEW_ARRIVAL_CATEGORY_ID,
    productDisplayConfig: {
      pageSize: PRODUCT_PAGE_SIZE,
      currentPage: selectedPage,
      sortBy: "created_at",
      soryDirection: "DESC",
    },
  });

  return (
    <>
      {/* <h4 className="new-arrival">สินค้าใหม่</h4> */}
      <div className="title d-flex align-items-center mb-0 mb-sm-3 mb-md-0">
        <h4 className="new-arrival">สินค้าใหม่</h4>
        <Link href="/new-arrival">
          <a className="view-all ms-auto">
            ดูทั้งหมด <i className="icon icon-arrow-right mx-2" />
          </a>
        </Link>
      </div>
      <div className="product-list-grid product-col-lg-5">
        {isLoadingHomeNewArrivalProducts ? (
          <SkeletonProductItem />
        ) : (
          dataHomeNewArrivalProducts?.items && (
            <div className="row">
              <div className="col">
                <SliderSwiper dataItems={dataHomeNewArrivalProducts?.items} />
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};
