import { HomeBestSellerProductsResponse } from "@interfaces/dto/products.dto";
import { getFilterQuery, getPageQuery } from "../products/_utils";
import { composeQueryParams } from "@utils/query.utils";
import { ProductDisplayConfig } from "@interfaces/components/product-filters.interface";

import useSWR from "swr";
import { fetcher } from "@api/_base";

export const useHomeBestSellerProducts = ({
  categoryId,
  productDisplayConfig,
}: {
  categoryId: number | undefined;
  productDisplayConfig: ProductDisplayConfig;
}): HomeBestSellerProductsResponse => {
  let query: string | undefined = undefined;
  const queryCat = getFilterQuery({
    field: "category_id",
    value: categoryId !== undefined ? categoryId.toString() : "0",
    layer: 0,
  });
  // [page]
  const { pageSize, currentPage, sortBy, soryDirection } = productDisplayConfig;
  const queryPage = getPageQuery({
    pageSize: pageSize,
    currentPage: currentPage,
    sortBy: sortBy,
    soryDirection: soryDirection
  });

  query = composeQueryParams([queryCat, queryPage]);
  const { data, error, mutate } = useSWR(`/nextapi/products?${query}`, fetcher);

  return {
    data: data ? data : undefined,
    isLoading: !error && !data,
    error: error,
    mutate: mutate,
  };
};
