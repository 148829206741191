import ContentLoader from "react-content-loader";

type Props = {};

const SkeletonHomeCategory = (props: Props) => {
  const bgColor = "#fbfbfb";
  const contentHeight = 330;
  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="d-none d-lg-block col-lg-3">
          <ContentLoader
            speed={2}
            width={"100%"}
            height={contentHeight}
            viewBox={`0 0 100% ${contentHeight}`}
            backgroundColor={bgColor}
            foregroundColor="#ecebeb"
          >
            <rect x="0" y="0" rx="3" ry="3" width="30%" height="20" />
            <rect x="0" y="30" rx="10" ry="10" width="100%" height="300" />
          </ContentLoader>
        </div>
        <div className="col-lg-9">
          <div className="row">
            <div className="col-lg-9">
              <ContentLoader
                speed={2}
                width={"100%"}
                height={contentHeight}
                viewBox={`0 0 100% ${contentHeight}`}
                backgroundColor={bgColor}
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="30%" height="20" />
                <rect x="0" y="30" rx="10" ry="10" width="100%" height="300" />
              </ContentLoader>
            </div>
            <div className="d-none d-lg-block col-lg-3">
              <ContentLoader
                speed={2}
                width={"100%"}
                height={contentHeight}
                viewBox={`0 0 100% ${contentHeight}`}
                backgroundColor={bgColor}
                foregroundColor="#ecebeb"
              >
                <rect x="0" y="0" rx="3" ry="3" width="30%" height="20" />
                <rect x="0" y="30" rx="10" ry="10" width="100%" height="300" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonHomeCategory;
