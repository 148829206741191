import { HomeRecommendItem } from "./HomeRecommendItem";
import { RECOMMEND_CATEGORY_ID } from "@const/recommend-category.const";
import { useHomeRecommendProducts } from "@api/home-recommend/home-recommend";
import Loader from "@components/atoms/Loader";
import { useEffect, useState } from "react";
import {
  PRODUCT_DEFAULT_PAGE,
  PRODUCT_DEFAULT_SORT_BY,
} from "@const/products.const";
import { ProductSortBy } from "@interfaces/components/product-filters.interface";
import { Product } from "@interfaces/dto/products.dto";
import SkeletonProductItem from "@components/atoms/skeleton/ui-product-item";

export const HomeRecommend = () => {
  const [recentView, setRecentView] = useState<string>(RECOMMEND_CATEGORY_ID);

  const [renderedProducts, setRenderedProducts] = useState<Product[]>([]);
  const [selectedPage, _setSelectedPage] =
    useState<number>(PRODUCT_DEFAULT_PAGE);
  // const [selectedSortBy, _setSelectedSortBy] = useState<ProductSortBy>(
  //   PRODUCT_DEFAULT_SORT_BY
  // );
  const [selectedSortBy, _setSelectedSortBy] = useState<ProductSortBy>("name");
  const RECOMMEND_PRODUCT_PAGE_SIZE = 15;

  // get RecommendProducts
  const {
    data: dataHomeRecommendProducts,
    isLoading: isLoadingHomeRecommendProducts,
    error: errorRecommendProducts,
  } = useHomeRecommendProducts({
    categoryId: String(recentView),
    productDisplayConfig: {
      pageSize: RECOMMEND_PRODUCT_PAGE_SIZE,
      currentPage: selectedPage,
      sortBy: selectedSortBy,
    },
  });

  // handle append products
  useEffect(() => {
    if (dataHomeRecommendProducts?.items) {
      if (selectedPage === 1) {
        setRenderedProducts(dataHomeRecommendProducts.items);
      } else {
        setRenderedProducts((prev) => [
          ...prev,
          ...dataHomeRecommendProducts.items,
        ]);
      }
    }
  }, [dataHomeRecommendProducts?.items, selectedPage]);

  const handleMoreProducts = () => {
    _setSelectedPage(selectedPage + 1);
  };

  // Recent View
  useEffect(() => {
    const storageRecentView = localStorage.getItem(
      "RECENT_VIEW_ID_STORAGE_KEY"
    );
    if (storageRecentView) {
      setRecentView(storageRecentView);
    }
  }, [recentView]);

  return (
    <>
      <div className="title-recommend mt-4 mt-md-4 mb-md-3">
        <h4>
          สินค้าแนะนำสำหรับคุณ
          <hr />
        </h4>
      </div>
      <div className="product-list-grid">
        {isLoadingHomeRecommendProducts ? (
          <SkeletonProductItem />
        ) : (
          dataHomeRecommendProducts?.items && (
            // <HomeRecommendItem
            //   dataRecommendProduct={dataHomeRecommendProducts?.items}
            // />
            <HomeRecommendItem dataRecommendProduct={renderedProducts} />
          )
        )}
      </div>
      {renderedProducts.length >=
        selectedPage * RECOMMEND_PRODUCT_PAGE_SIZE && (
        <div className="my-4 text-center">
          <a
            onClick={handleMoreProducts}
            className="btn btn-outline-secondary btn-readmore rounded-2"
            role="button"
            aria-pressed="true"
          >
            ดูเพิ่มเติม
          </a>
        </div>
      )}
    </>
  );
};
