import HomeProductGroupBanner from "./HomeProductGroupBanner";
import HomeProductGroupTabMenu from "./HomeProductGroupTabMenu";
import HomeProductGroupItem from "./HomeProductGroupItem";
import { useState } from "react";
import {
  PRODUCT_DEFAULT_PAGE,
  PRODUCT_GROUP_DEFAULT_SORT_BY,
} from "@const/products.const";
import {
  useHomeProductGroupBanner,
  useHomeProductGroup,
  useHomeProductGroupTabMenu,
} from "@api/home-product-group/home-product-group";
import { ProductSortBy } from "@interfaces/components/product-filters.interface";
import Loader from "@components/atoms/Loader";
import SliderSwiper from "@components/atoms/SliderSwiper";
import SkeletonProductGroupBanner from "@components/atoms/skeleton/ui-product-group-banner";
import SkeletonProductGroupItem from "@components/atoms/skeleton/ui-product-group-item";

type Props = {
  categoryId: string;
  sectionId: string;
};

const HomeProductGroup = ({ categoryId, sectionId }: Props) => {
  // set paging & sortby states
  const [selectedPage, setSelectedPage] =
    useState<number>(PRODUCT_DEFAULT_PAGE);
  const [selectedSortBy, _setSelectedSortBy] = useState<ProductSortBy>(
    PRODUCT_GROUP_DEFAULT_SORT_BY
  );

  // get ProductGroup data
  const { data: dataHomeProductGroup, isLoading: isLoadingHomeProductGroup } =
    useHomeProductGroup({
      categoryId: categoryId,
      productDisplayConfig: {
        pageSize: 8,
        currentPage: selectedPage,
        sortBy: selectedSortBy,
        soryDirection: "ASC",
      },
    });

  // get ProductGroup Banner
  const {
    data: dataProductGroupBanner,
    isLoading: isLoadingProductGroupBanner,
  } = useHomeProductGroupBanner(sectionId);

  // get ProductGroup Tab Menu
  const {
    data: dataProductGroupTabMenu,
    isLoading: isLoadingProductGroupTabMenu,
  } = useHomeProductGroupTabMenu(sectionId);

  return (
    <>
      <div className="title d-md-flex align-items-center justify-content-between">
        {dataProductGroupBanner?.banner_left &&
          dataProductGroupBanner?.banner_left.length > 0 && (
            <h4 className="mt-3">
              {dataProductGroupBanner?.banner_left[0].title}
            </h4>
          )}

        {/* Tab Menu */}
        {/* <div className="category-responsive">
          {isLoadingProductGroupTabMenu ? (
            <Loader />
          ) : (
            dataProductGroupTabMenu && (
              <HomeProductGroupTabMenu menus={dataProductGroupTabMenu} />
            )
          )}
        </div> */}
      </div>

      <div className="category-product-list">
        <div className="row g-0">
          <div className="col product-group-banner rounded-4 me-2 d-none d-lg-block">
            {isLoadingProductGroupBanner ? (
              <SkeletonProductGroupBanner />
            ) : (
              dataProductGroupBanner && (
                <HomeProductGroupBanner
                  banners={dataProductGroupBanner?.banner_left}
                />
              )
            )}
          </div>
          <div className="product-group-items">
            <div className="product-list-grid">
              {isLoadingHomeProductGroup ? (
                <SkeletonProductGroupItem />
              ) : (
                dataHomeProductGroup?.items && (
                  <SliderSwiper
                    bannerImg={dataProductGroupBanner?.banner_left}
                    dataItems={dataHomeProductGroup?.items}
                    sliderPerView={4}
                  />
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeProductGroup;
