// import { useWindowDimensions } from "@utils/common.util";
import { useEffect, useState } from "react";
import ImageModal from "../image-modal/ImageModal";
import { useHomePromotionDialy } from "@api/home-promotion-daily/home-promotion-daily";
import { HomePromotionDialyItem } from "@interfaces/dto/home-promotion-daily.dto";

type Props = {};

const HomePromotionDaily = (props: Props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  //   let sizeScreen = useWindowDimensions().width;

  const { data, isLoading, error } = useHomePromotionDialy();
  useEffect(() => {
    const hasSeenBanner = document.cookie.includes("bannerShown=true");
    if (!hasSeenBanner) {
      setShow(true);
      document.cookie = "bannerShown=true; max-age=86400;"; // 86400 seconds = 1 day
    }
  }, []);

  return (
    <>
      {data && (
        <ImageModal
          isShow={show}
          handleClose={handleClose}
          dataImages={data?.popup_banner as HomePromotionDialyItem[]}
          isTransparent={true}
        />
      )}
    </>
  );
};

export default HomePromotionDaily;
