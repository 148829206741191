import React from "react";

type Props = {};

export const FullscreenLoader = (props: Props) => {
  return (
    <div className="position-fixed left-0 top-0 w-100 h-100 text-center justify-content-center d-flex flex-column align-items-center bg-dark bg-gradient opacity-25">
      <div className="spinner-border text-danger" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
