import type { NextPage } from "next";

import { HomePromotion } from "@components/features/home-promotion/HomePromotion";
import { HomeBestSeller } from "@components/features/home-best-seller/HomeBestSeller";
import { HomeFlashsale } from "@components/features/home-flashsale/HomeFlashsale";
import { HomeNewArrival } from "@components/features/home-new-arrival/HomeNewArrival";
import { HomeRecommend } from "@components/features/home-recommend/HomeRecommend";
import HomeHotBrand from "@components/features/home-hot-brand/HomeHotBrand";
import HomeProductGroup from "@components/features/home-product-group/HomeProductGroup";
import * as CategoryConst from "@const/product-group-category.const";
import HomeCategory from "@components/features/home-category/HomeCategory";
import {
  convertKeywordstoArray,
  convertRobotstoBoolean,
} from "@utils/seo.util";
import { NextSeo } from "next-seo";
import { useEffect, useState } from "react";
import TagManager, { DataLayerArgs } from "react-gtm-module"; // DataLayer GTM
import HomePromotionDaily from "@components/features/home-promotion-daily/HomePromotionDaily";
import { SEO_SITEURL } from "@const/seo.const";
import { GetStaticProps } from "next";
import { serverSideMetadataCms } from "@api/server-side/cms-page";
import { SWRConfig } from "swr";
import type { SWRConfiguration } from "swr";
import { useAppDispatch } from "@stores/store";
import { useSession } from "next-auth/react";
import { SocialLoginDto } from "@interfaces/dto/user-data.dto";
import {
  socialLogin,
  getSession,
  isAuthenticatedSelector,
} from "@stores/slices/userSlice";
import { useSelector } from "react-redux";
import { FullscreenLoader } from "@components/atoms/FullscreenLoader";

const Home: NextPage = ({ fallback }: SWRConfiguration) => {
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const [isLoading, setIsLoading] = useState(false);
  const { noIndex, noFollow } = convertRobotstoBoolean(
    fallback?.metatags.robots as string
  );
  const keywords = convertKeywordstoArray(
    fallback?.metatags.keywords as string
  );

  // Datalayer GTM
  useEffect(() => {
    const variables: Record<string, any> = {
      event: "topvalue.next",
      Currency: "THB",
      PageType: "Homepage",
    };
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: { variables },
    };
    TagManager.dataLayer(dataLayerArgs);
  }, []);

  // Session Social login
  const dispatch = useAppDispatch();
  const { data: fetchSession, status } = useSession();
  useEffect(() => {
    const getUser = async () => {
      if (!isAuthenticated) {
        if (fetchSession && fetchSession.user) {
          setIsLoading(true);
          if (fetchSession.user?.name) {
            // other providers
            const userEmail = fetchSession?.user?.email;
            const userName = fetchSession?.user?.name;
            const inputs = {
              email: String(userEmail),
              name: String(userName),
              provider: "",
            };
            await dispatch(socialLogin(inputs));
            const { payload } = await dispatch(getSession());
            if (payload.token) {
              setIsLoading(false);
            }
          } else {
            // apple provider
            if (fetchSession.user?.email) {
              const email = fetchSession?.user?.email;
              const fixName =
                email.substring(0, email.lastIndexOf("@")) +
                " " +
                email.substring(0, email.lastIndexOf("@"));
              const datasCustomer: SocialLoginDto = {
                email: email,
                name: fixName,
                provider: "",
              };
              await dispatch(socialLogin(datasCustomer));
              const { payload } = await dispatch(getSession());
              if (payload.token) {
                setIsLoading(false);
              }
            }
          }
        }
      }
    };
    getUser();

    return () => {};
  }, [dispatch, fetchSession]);

  if (isLoading) {
    return <FullscreenLoader />;
  }

  return (
    <>
      {/* SEO */}
      <SWRConfig value={{ fallback }}>
        {fallback?.metatags && (
          <NextSeo
            title={fallback?.metatags.title}
            description={fallback?.metatags.description}
            additionalMetaTags={keywords}
            noindex={noIndex}
            nofollow={noFollow}
            openGraph={{
              title: fallback?.metatags.title,
              description: fallback?.metatags.description,
            }}
            canonical={`${SEO_SITEURL}`}
          />
        )}
      </SWRConfig>

      {/* Promotion Daily (Popup)*/}
      <HomePromotionDaily />

      {/* HOMEPAGE */}
      <HomeCategory />
      <section className="product-section">
        <div className="container-fluid">
          <HomeFlashsale />
          <HomePromotion />
          <HomeHotBrand />
          <HomeBestSeller />
          <HomeNewArrival />
          <HomeProductGroup
            categoryId={CategoryConst.HOME_PRODUCT_GROUP_1}
            sectionId={CategoryConst.HOME_PRODUCT_GROUP_1_SECTION_1}
          />
          <HomeProductGroup
            categoryId={CategoryConst.HOME_PRODUCT_GROUP_2}
            sectionId={CategoryConst.HOME_PRODUCT_GROUP_2_SECTION_2}
          />
          <HomeProductGroup
            categoryId={CategoryConst.HOME_PRODUCT_GROUP_3}
            sectionId={CategoryConst.HOME_PRODUCT_GROUP_3_SECTION_3}
          />
          <HomeProductGroup
            categoryId={CategoryConst.HOME_PRODUCT_GROUP_4}
            sectionId={CategoryConst.HOME_PRODUCT_GROUP_4_SECTION_4}
          />
          <HomeProductGroup
            categoryId={CategoryConst.HOME_PRODUCT_GROUP_5}
            sectionId={CategoryConst.HOME_PRODUCT_GROUP_5_SECTION_5}
          />
          <HomeProductGroup
            categoryId={CategoryConst.HOME_PRODUCT_GROUP_6}
            sectionId={CategoryConst.HOME_PRODUCT_GROUP_6_SECTION_6}
          />
          <HomeRecommend />
        </div>
      </section>
    </>
  );
};
export default Home;

export const getStaticProps: GetStaticProps = async ({ params }) => {
  try {
    const { data: metaDatas } = await serverSideMetadataCms("home");
    if (metaDatas) {
      return {
        props: {
          fallback: {
            metatags: metaDatas,
          },
        },
        revalidate: 30,
      };
    } else {
      return {
        props: {
          fallback: null,
        },
        revalidate: 30,
      };
    }
  } catch {
    return {
      props: {
        fallback: null,
      },
      revalidate: 30,
    };
  }
};
